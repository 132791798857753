import React from 'react'
import { Link } from "gatsby"
import MetaData from "../components/MetaData"

import RightArrow from '../assets/right-arrow.svg'

export const Head = (props) => <MetaData title="Page Not Found" description="Page not found." {...props} />

const NotFoundPage = ({location}) => (
  <div className='not-found'>
    <h2>404</h2>
    <p>This page doesn&#39;t exist. It might have been moved or maybe it never existed at all.</p>
    <Link state={{prevPath: location.href}} to="/">Go Home<RightArrow /></Link>
  </div>
)

export default NotFoundPage